.title {
    font-family: 'Lato', sans-serif;
    font-weight: 900;
    font-size: 45px;
    line-height: 54px;
    color: #03688D;
    text-align: center;
}

.subtitle {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
    color: #03688D;
    text-align: center;
}

.spacer15U {
    padding: 15px 0px 0px 0px;
}

.spacer30U {
    padding: 30px 0px 0px 0px;
}

.spacer60U {
    padding: 60px 0px 0px 0px;
}

.section_001{
    font-family: 'Lato', sans-serif;
    font-size: 21px;
    font-weight: 400;
    line-height: 36px;
    text-align: justify;
    color: #595b5d;
    margin: 1em;
}

.section_002 {
    font-family: 'Lato', sans-serif;
    font-size: 21px;
    font-weight: 400;
    line-height: 36px;
    text-align: justify;
    color: #595b5d;
    margin: 1em;
    padding: 1em;
    background-color: #e6f3f8;
}

.margin1em {
    margin: 1em;
}

.sectionUnderLine {
    height: 2px;
    border-style: none;
    border-color: transparent;
    background-color: #3B9ABD;
    position: relative;
}

.custom_page {
    margin: 2em auto;
    max-width: 75em;
}

.image{
    text-align: center;
    display: block;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-bottom: 5%;
    width:
  }